<template>
  <div
    id="app"
    :class="[
      isGameView ? 'resize-page' : '',
      isLanding ? 'landing' : '',
      $route.name ? 'site-' + $route.name : '',
      ((isSpecialPage && !$store.getters['auth/isLoggedIn']) ||
        isManagementPage) &&
      smallWindow
        ? 'mobile-view'
        : '',
      $store.state.auth.acpMode ? 'acp-border position-relative' : '',
    ]"
  >
    <span
      v-if="$store.state.auth.acpMode"
      id="acp-warning"
      class="text-danger bold position-sticky"
    >
      ACHTUNG! ACP-Modus! Bitte achtsam verhalten und unbedingt wieder
      ausloggen!
    </span>
    <fall-back-page v-if="showFallBackPage"></fall-back-page
    ><template v-else>
      <MainContent />

      <broadcasting @socketStatus="handleSocketStatus" @logout="unsubscribe" />
      <preloader v-if="preloadContent"></preloader>
      <pipeline v-if="isLoggedIn"></pipeline>
      <global-popups v-if="isLoggedIn"></global-popups
    ></template>
  </div>
</template>

<script>
import broadcasting from "./broadcasting.vue";
import preloader from "./components/preloader/preloader";
import * as tournamentAPI from "./API/tournament.js";
import AnimationParsingFunctions from "@/components/animation/animation-parsing-functions";
import AnimationFullAccomplishment from "./components/animation/animation-full-accomplishment";
import offerModal from "@/mixins/offerModal.js";
import loadScripts from "@/mixins/loadScripts";
import AnimationKingWin from "@/components/animation/animation-king-win";
import Pipeline from "@/components/pipeline/pipeline";
import GlobalPopups from "@/components/global-popups";
import FallBackPage from "./views/FallBackPage.vue";
import wsChannels from "@/mixins/wsChannels.js";

export default {
  name: "App",
  components: {
    GlobalPopups,
    Pipeline,
    preloader,
    broadcasting,

    MainContent: () =>
      import(/* webpackChunkName: "main" */ "@/views/MainContent.vue"),
    FallBackPage,
  },
  mixins: [
    AnimationParsingFunctions,
    AnimationFullAccomplishment,
    offerModal,
    loadScripts,
    AnimationKingWin,
    wsChannels,
  ],
  data() {
    return {
      socket: null,
    };
  },
  computed: {
    isLoggedIn: function () {
      return this.$store.getters["auth/isLoggedIn"];
    },

    currentUserId() {
      return this.$store.getters["user/currentUser"].id;
    },

    isConnected: function () {
      return (
        this.socket &&
        this.socket.connector.pusher.connection.connection !== null
      );
    },

    isManagementPage() {
      return (
        this.$route.matched[0] && this.$route.matched[0].path === "/management"
      );
    },
    isSpecialPage() {
      return (
        this.$route.path === "/imprint" ||
        this.$route.path === "/privacy" ||
        this.$route.path === "/tac" ||
        this.$route.path === "/raffle_tac"
      );
    },

    isGameView() {
      return (
        this.$route.name === "game" ||
        this.$route.name === "kingspath-game" ||
        this.$route.name === "tournament-game"
      );
    },
    isLanding() {
      return (
        (!this.isLoggedIn && this.$route.path === "/") || this.isManagementPage
      );
    },
    preloadContent() {
      return this.isLoggedIn && !this.isSpecialPage && !this.isManagementPage;
    },
    teamMember() {
      return this.$store.getters["teams/isTeamMember"];
    },
    championsBattleActive() {
      return this.$store.getters["champions/getCurrentBattle"] !== null;
    },

    musicPreference() {
      return this.$store.getters["sound/getMusicPreference"];
    },
    smallWindow() {
      return this.$store.state.windowWidth < 1024;
    },
    showFallBackPage() {
      if (this.isLoggedIn) {
        return this.smallWindow;
      } else {
        return (
          this.smallWindow && !this.isSpecialPage && !this.isManagementPage
        );
      }
    },
    tournamentActive() {
      return this.$store.state.tournaments.currentTournamentId !== null;
    },
  },
  watch: {
    musicPreference() {
      if (this.musicPreference) {
        this.playBackgroundMusic(true);
      } else {
        this.stopBackgroundMusic();
      }
    },

    teamMember: {
      handler() {
        if (this.teamMember) {
          this.$store.dispatch("teams/getUserTeamInfo").then(() => {
            if (!this.$store.getters["teams/teamActive"]) {
              this.getTeamNotifications();
            }
          });
        }
      },
    },
    tournamentActive: {
      handler() {
        if (this.tournamentActive) {
          let id = this.$store.state.tournaments.currentTournamentId;
          this.getTournamentUpdates(id);
        }
      },
    },
    championsBattleActive: {
      handler() {
        if (this.championsBattleActive) {
          this.getChampionsBattleNotifications();
        }
      },
    },

    isLoggedIn: {
      handler(data) {
        if (data) {
          this.loadScriptsExternal();
        }
      },
    },

    $route(to) {
      this.playBackgroundMusic(true);
      this.firebaseSetScreen(to.path, "default");
    },
  },
  created() {
    window.addEventListener("resize", this.onResize);
    this.onResize();
  },

  mounted() {
    this.$store.dispatch("resetStore");

    // this.getMobileOperatingSystem(); //TODO: Use deeplink from App
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },

  methods: {
    onResize() {
      this.$store.commit("updateWindowWidth", window.innerWidth);
    },
    handleSocketStatus(payload) {
      if (payload) {
        if (payload.connector.pusher.connection.connection) {
          this.socket = payload;
          this.subscribeToSocketChannels();

          if (this.teamMember) {
            this.$store.dispatch("teams/getUserTeamInfo").then((res) => {
              if (res.data.data.team.status === "ACTIVE") {
                this.setUpChat();
              }
            });
          }
          if (this.championsBattleActive) {
            this.getChampionsBattleNotifications();
          }
        }
      } else {
        this.unsubscribe();
      }
    },
    subscribeToSocketChannels() {
      if (this.socket) {
        const userId = this.$store.getters["user/currentUser"].id;
        const privateSocket = this.socket.private(`user.${userId}`);
        const publicSocket = this.socket.channel("system");

        Object.keys(this.privateActions).forEach((messageId) => {
          const handler = this.privateActions[messageId];
          privateSocket.listen(messageId, handler);
        });
        Object.keys(this.publicActions).forEach((messageId) => {
          const handler = this.publicActions[messageId];
          publicSocket.listen(messageId, handler);
        });
      }
    },
    unsubscribe() {
      let channels = Object.keys(this.socket.connector.channels);
      channels.forEach((channel) => {
        this.socket.leave(channel);
      });
    },
    getTournamentUpdates(id) {
      const pusher = this.socket.connector.pusher;
      pusher.subscribe(`tournament.${id}`).bind("tournament.update", (e) => {
        this.$store.commit("tournaments/setTournamentCurrentUpdateData", e);
      });
      // this.socket
      //   .join(`tournament.${id}`)
      //   .listen(".tournament.update", (e) => console.log("haaa", e));
    },

    setUpChat() {
      if (!this.$store.state.chat.chatHistoryFetched) {
        this.$store.dispatch("chat/fetchChatHistory");
      }
      this.socket
        .join(`team.${this.$store.state.teams.userTeam.id}`)
        .here((users) =>
          this.$store.commit("chat/setListOfOnlineTeamMembers", users)
        )
        .joining((user) => {
          this.$store.commit("chat/addTeamMemberToList", user);
        })
        .leaving((user) =>
          this.$store.commit("chat/removeTeamMemberFromList", user)
        )
        .listen(".team.chat", (message) => {
          this.$store.commit("chat/addMessageToChat", {
            message: message,
            route: this.$route.path,
          });
        })
        .listen(".team.chat.user_card_request", (request) => {
          this.$store.commit("chat/addMessageToChat", {
            message: request,
            route: this.$route.path,
          });
        });
    },
    getTeamNotifications() {
      this.socket
        .join(`team.${this.$store.getters["user/currentUser"].team_id}`)
        .listen(".team.active", () => {
          console.log("team active!");
          this.$store.commit("popups/setAlertBannerContent", {
            alertHeading: this.$t("teams.now_active.title"),
            alertText: this.$t("teams.now_active.message"),
            type: "friend",
            variant: "success",
          });
        });
    },
    getChampionsBattleNotifications() {
      this.socket
        .join(
          `user.battle.${this.$store.getters["champions/getCurrentBattle"].battle_id}`
        )
        .listen(".user.battle.update", (e) => {
          let battle = this.$store.getters["champions/getCurrentBattle"];
          if (battle && battle.battle_id !== e.battle) {
            return;
          }

          if (this.currentUserId === e.user_id) {
            console.log("Hier Websocket", e);

            //update own values
            const gold = e.gold;
            const user_id = e.user_id;
            const ended_at = e.ended_at;
            battle.gold = gold;
            battle.user_id = user_id;
            battle.ended_at = ended_at;

            this.$store.commit("champions/setCurrentBattle", battle);
          } else {
            //update opponent values
            this.$store.commit("champions/setCurrentOpponentId", null);
            this.$store.commit("champions/setCurrentOpponentId", e.user_id);
          }
        });
    },

    fetchTournamentHistoryData: async function (win_data) {
      await tournamentAPI.fetchHistoryTournament().then((res) => {
        const tournament_id = win_data.tournament_history_id;
        let tournaments = res.list;
        let result;
        for (let i = 0; i < tournaments.length; i++) {
          if (tournaments[i].tournament_history_id === tournament_id) {
            result = tournaments[i];
            result["gold_amount"] = win_data["gold_amount"];
            result["crown_amount"] = win_data["crown_amount"];
            result["loyalty_amount"] = win_data["loyalty_amount"];
            break;
          }
        }

        this.$store
          .dispatch("tournaments/updateTournamentWin", result)
          .then(() => {
            this.$bvModal.show("modal-tournament-win" + result.id);
          });
      });
    },
  },
};
</script>
<style lang="scss">
.acp-border {
  border: 5px solid red;
  #acp-warning {
    top: 70px;
    z-index: 5000;
  }
}
</style>
